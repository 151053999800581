import config from "@/config";
import clubAuth from "@/lib/club-auth";

const module = {
	namespaced: true,
	state: {},
	mutations: {},
	actions: {
		async getTimeslotsHeatmapByWeek(context, { week, year }) {
			const res = await clubAuth.http.get(`/tijdsloten-heatmap/week/${week}/jaar/${year}`);
			return res.data;
		},
		async getTimeslotsByWeek(context, { from, to, locationId }) {
			return (
				await clubAuth.http.post(`${config.reserveer.server}/tijdsloten`, {
					startDatum: from,
					eindDatum: to,
					vestiging: locationId,
				})
			).data;
		},
	},
};

export default module;
