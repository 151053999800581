import {
	API_CLIENT_ID,
	API_CLIENT_SECRET,
	API_SERVER_CLUB,
	API_SERVER_COACH,
	API_SERVER_RESERVEER,
} from "@/lib/constants/env";

export const config = {
	coreBackend: process.env.VUE_APP_CORE_BACKEND,

	coach: {
		server: API_SERVER_COACH,
		client_id: API_CLIENT_ID,
		client_secret: API_CLIENT_SECRET,
	},
	club: {
		server: API_SERVER_CLUB,
		client_id: API_CLIENT_ID,
		client_secret: API_CLIENT_SECRET,
	},
	reserveer: {
		server: API_SERVER_RESERVEER,
		client_id: API_CLIENT_ID,
		client_secret: API_CLIENT_SECRET,
	},
};

export default config;
