<template>
	<div v-sticky="{ zIndex: 4444, stickyTop: 0 }">
		<nav class="pg_side-bar">
			<div class="pg_side-bar_sticky-container">
				<div class="pg_side-bar_sticky">
					<div class="pg_side-bar_links">
						<router-link to="/kpi-dashboard" class="pg_side-bar_link">
							<icon :icon-id="'icon_kpi'"></icon>
							<span class="pg_side-bar_link_text">
								KPI
								<span class="show-for-large">dashboard</span>
							</span>
						</router-link>
						<router-link to="/leden" class="pg_side-bar_link">
							<icon :icon-id="'icon_leden'"></icon>
							<span class="pg_side-bar_link_text">Leden</span>
						</router-link>
						<router-link to="/coaches" class="pg_side-bar_link">
							<icon :icon-id="'icon_coaches'"></icon>
							<span class="pg_side-bar_link_text">Coaches</span>
						</router-link>
						<router-link to="/groepslessen" class="pg_side-bar_link">
							<icon :icon-id="'icon_groepsles'"></icon>
							<span class="pg_side-bar_link_text">Groepslessen</span>
						</router-link>
						<router-link to="/lidmaatschappen" class="pg_side-bar_link">
							<icon :icon-id="'icon_lidmaatschappen'"></icon>
							<span class="pg_side-bar_link_text">Lidmaatschappen</span>
						</router-link>
						<router-link v-if="userInfo.admin" to="/groepsles-planning" class="pg_side-bar_link">
							<icon :icon-id="'icon_planning'"></icon>
							<span class="pg_side-bar_link_text">Groepsles Planning</span>
						</router-link>
						<router-link
							v-if="userInfo.admin"
							:to="{ name: 'Coach momenten' }"
							class="pg_side-bar_link"
						>
							<icon icon-id="icon_coach"></icon>
							<span class="pg_side-bar_link_text">Coach momenten</span>
						</router-link>
					</div>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
import VueSticky from "vue-sticky";
import { mapState } from "vuex";

export default {
	name: "SideBar",
	directives: {
		sticky: VueSticky,
	},
	computed: {
		...mapState("auth", ["userInfo"]),
	},
};
</script>

<style lang="scss">
////
////    GENERICS
////

.pg_side-bar {
	position: fixed;
	z-index: 222;
	bottom: 0;
	width: 100%;
	background: $default-background-light;
	box-shadow: 0 0 20px rgba($brand-black, 0.1);
	@include breakpoint(medium) {
		box-shadow: none;
		display: flex;
		position: absolute;
		flex-direction: column;
		width: $pg_side-bar_width_medium;
		height: 100%;
	}
	@include breakpoint(xlarge) {
		width: $pg_side-bar_width_large;
	}
}

////
////    STICKY
////

.pg_side-bar_sticky-container {
}
.pg_side-bar_sticky {
	height: 100%;
}

////
////    CTA BOTTOM
////

.pg_side-bar_cta-bottom {
	position: fixed;
	z-index: 222;
	display: none;
	bottom: 0;
	padding: $default-padding;
	border-top: $default-border;
	text-align: center;

	@include breakpoint(xlarge) {
		display: block;
		width: $pg_side-bar_width_large;
	}
}

////
////    LINKS
////

.pg_side-bar_links {
	display: flex;
	justify-content: space-around;
	@include breakpoint(medium) {
		display: block;
		padding-top: $default-padding;
	}
}
.pg_side-bar_link {
	$svg-size: 32px;

	display: inline-flex;
	flex-direction: column;
	align-items: center;
	padding: $default-padding * 0.7 0;
	text-transform: uppercase;
	color: rgba($brand-primary, 0.7);
	font-size: 65%;
	letter-spacing: 0.5px;
	transition: color $default-transition-speed;

	@include breakpoint(medium) {
		width: 100%;
		font-size: 85%;
		padding: $default-padding;
		border-left: 4px solid $brand-white;
	}

	.svg-container {
		width: $svg-size * 0.7;
		height: $svg-size * 0.7;
		margin-bottom: 5px;
		@include breakpoint(medium) {
			margin-bottom: 0;
			width: $svg-size;
			height: $svg-size;
		}
	}
	svg {
		width: $svg-size * 0.7;
		height: $svg-size * 0.7;
		transition: fill $default-transition-speed;
		fill: $default-icon-color-gray;
		@include breakpoint(medium) {
			width: $svg-size;
			height: $svg-size;
		}
	}

	@include breakpoint(xlarge) {
		flex-direction: row;
		.svg-container {
			margin-right: calc($default-padding / 2);
		}
		&:hover {
			color: $brand-secondary;
			svg {
				fill: $brand-secondary;
			}
		}
	}

	&.s_active {
		color: rgba($brand-primary, 1);
		@include breakpoint(medium) {
			background: $brand-lightest-gray;
			border-left: 4px solid $brand-primary;
		}
		svg {
			fill: $brand-secondary;
		}
		&:hover {
			color: $brand-secondary;
		}
	}
}

.pg_side-bar_link_text {
	white-space: nowrap;
	@include breakpoint(medium) {
		display: none;
	}
	@include breakpoint(xlarge) {
		display: inline-block;
	}
}

#side-bar-end {
	position: absolute;
	bottom: 0;
	width: 100%;
	// height: 30px;
	// background: red;
}
</style>
