import config from "@/config";
import clubAuth from "@/lib/club-auth";
import { logger } from "@/logger";

const http = clubAuth.http;

const rooster = {
	namespaced: true,
	state: {
		rooster: {},
		les: {},
	},

	// returns array with the amount of lessen per groepsles in current rooster
	getters: {
		getAmountGroepslessenOfRooster: (state) => (id) =>
			(state.rooster.roosterItems && state.rooster.roosterItems.length
				? state.rooster.roosterItems.filter((les) => les.groepslesId === id)
				: []
			).length,
	},

	mutations: {
		setRooster(state, payload) {
			state.rooster = payload;
		},
		setLes(state, payload) {
			state.les = payload;
		},
	},

	actions: {
		getRooster({ commit }, payload) {
			http
				.get(`${config.reserveer.server}/roosters/${payload}`)
				.then((response) => {
					if (response.status === 200) {
						commit("setRooster", response.data);
					}
				})
				.catch((err) => {
					logger.error(err.response);
				});
		},
		getLes({ commit }, payload) {
			return new Promise((resolve, reject) => {
				http
					.get(`${config.reserveer.server}/lessen/${payload}`)
					.then((response) => {
						if (response.status === 200) {
							commit("setLes", response.data);
							resolve(response.data);
						}
					})
					.catch((err) => {
						logger.error(err.response);
						reject(err);
					});
			});
		},
		addLes({ dispatch }, payload) {
			return new Promise((resolve, reject) => {
				http
					.post(`${config.reserveer.server}/roosters`, payload)
					.then((response) => {
						if (response.status === 200) {
							dispatch("getRooster", response.data.id);
							resolve();
						}
					})
					.catch((err) => {
						logger.error(err.response);
						reject(err);
					});
			});
		},
		getVestigingen() {
			return http.get(`${config.club.server}/vestigingen`).then((res) => res.data);
		},
		getGroepslessen() {
			return http.get(`${config.reserveer.server}/groepslessen`).then((res) => res.data);
		},
		getTrainers() {
			return http.post(`${config.reserveer.server}/trainers`, {}).then((res) => res.data);
		},
		getLessenOfWeek(_, { week, year }) {
			return http
				.post(`${config.reserveer.server}/lessen/week/${week}/jaar/${year}`, {})
				.then((data) => {
					return data.data;
				});
		},
		getHeatMapLessenOfWeek(_, { week, year }) {
			return http
				.get(`${config.club.server}/heatmap/week/${week}/jaar/${year}`, {})
				.then((data) => {
					return data.data;
				});
		},
		getGeslotenDagen() {
			return http.get(`${config.reserveer.server}/geslotendagen`).then((res) => res.data);
		},
		removeLes({ dispatch, state }, payload) {
			return new Promise((resolve, reject) => {
				http
					.delete(`${config.reserveer.server}/roosteritems/${payload}`, payload)
					.then((response) => {
						if (response.status === 200) {
							dispatch("getRooster", state.rooster.id);
							resolve();
						}
					})
					.catch((err) => {
						logger.error(err.response);
						reject(err);
					});
			});
		},
	},
};

export default rooster;
