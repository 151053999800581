<template>
	<div>
		<div class="pg_top-bar">
			<div class="pg_top-bar_container">
				<div class="pg_top-bar_container_left">
					<router-link to="/" class="pg_top-bar_logo">
						<icon :icon-id="'pg_logo'"></icon>
					</router-link>
					<!-- <div class="pg_top-bar_stores" v-show="$route.name !== 'home'"> -->
					<div v-if="showBranchPicker" class="pg_top-bar_stores">
						<select
							v-if="userInfo.admin"
							:value="branch ? branch.id : null"
							class="v_transparent"
							@input="branch = $event.target.value"
						>
							<option v-for="(item, i) in branches" :key="`branch-option-${i}`" :value="item.id">
								{{ item.vestigingNaam }}
							</option>
						</select>
						<h5 v-else>{{ branch.vestigingNaam }}</h5>
					</div>
				</div>
				<div class="pg_top-bar_container_right">
					<user></user>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from "lodash";
import { mapMutations, mapState } from "vuex";
import { shouldShowPeriodPicker } from "@/lib/should-show-period-picker";
import User from "../elements/User.vue";

export default {
	name: "TopBar",
	components: {
		User,
	},
	computed: {
		...mapState("auth", ["userInfo"]),
		...mapState("branches", {
			branches: "items",
			selected: "selected",
		}),
		branch: {
			get() {
				if (this.userInfo && this.userInfo.vestiging) {
					this.selectBranch(_.find(this.branches, { mappingNaam: this.userInfo.vestiging }));
				}

				return this.selected ? this.selected : {};
			},
			set(value) {
				const branch = this.branches.find((branch) => branch.id === parseInt(value));
				return this.selectBranch(branch);
			},
		},
		showBranchPicker() {
			return shouldShowPeriodPicker(this.$route.name) && this.$route.name !== "Coach momenten";
		},
	},
	methods: {
		...mapMutations("branches", {
			selectBranch: "select",
		}),
	},
};
</script>

<style lang="scss">
////
////    GENETRICS
////

.pg_top-bar {
	background: $brand-white;
	// background-image: url('/static/img/bg/bg_top-bar.png');
	padding: $default-padding;
	position: fixed;
	top: 0;
	width: 100%;
	height: $pg_top-bar_height;
	display: flex;
	align-items: center;
	@include breakpoint(medium) {
		height: $pg_top-bar_height_medium;
	}
}

.pg_top-bar_container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

////
////    AREAS
////

.pg_top-bar_container_left {
	display: flex;
	align-items: center;
}

////
////    LOGO
////

.pg_top-bar_logo {
	padding-right: $default-padding;

	&.s_active {
		background-color: white;
	}
	.svg-container {
		width: 80px;
		@include breakpoint(medium) {
			width: 100px;
		}
		@include breakpoint(large) {
			width: 140px;
		}
	}
	svg {
		width: 80px;
		fill: $brand-secondary;

		@include breakpoint(medium) {
			width: 100px;
		}
		@include breakpoint(large) {
			width: 140px;
		}
	}
	@include breakpoint(large) {
		padding-right: 0;
		width: $pg_side-bar_width_large - $default-padding;
		display: flex;
		justify-content: center;
	}
}

////
////    STORES
////

.pg_top-bar_stores {
	border-left: solid 1px $default-border-color;
	padding-left: $default-padding;
	select {
		margin: 0;
		@include breakpoint(large) {
			font-size: 120%;
		}
	}
}
</style>
