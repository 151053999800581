<template>
	<div class="pg_content_1-1">
		<header class="pg_header">
			<div class="pg_header_heading">
				<h1 class="s_elem-has-icon-left a_fill_default">Bevestig</h1>
			</div>
			<div class="pg_header_description">
				<p v-if="data && data.message">{{ data.message }}</p>
				<p v-else>Weet je zeker dat je deze wijziging wil doorvoeren?</p>
			</div>
		</header>

		<hr />

		<div class="a_flex a_justify_flex-end">
			<button
				class="button v_ghost_brand_secondary v_has-icon-right v_smaller a_margin-left-10"
				@click="onClose"
			>
				Annuleren
			</button>
			<button
				class="button v_ghost_brand_primary v_has-icon-right v_icon-size-10 v_smaller a_margin-left-10"
				@click="confirm"
			>
				Bevestig
			</button>
		</div>
	</div>
</template>

<script>
import { logger } from "@/logger";

export default {
	name: "ModalConfirm",
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	methods: {
		onClose() {
			if (this.data.cancelCallback) {
				this.data.cancelCallback();
			}
			this.$emit("close");
		},
		confirm() {
			if (this.data.callback) {
				return this.data.callback();
			}
			logger.log("No callback provided, closing modal.");
			this.$emit("close");
		},
	},
};
</script>

<style scoped lang="scss"></style>
