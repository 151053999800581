import config from "@/config";
import { ClubApi } from "./club.api";
import { CoachApi } from "./coach.api";
import type { ApiConfig } from "./core.api";
import { ContentType, CoreApi } from "./core.api";
import { ReserveerApi } from "./reserveer.api";

const sharedOptions: ApiConfig = {
	headers: {
		"Content-Type": ContentType.Json,
	},
};

export const coreApi = new CoreApi({
	baseURL: config.coreBackend,
	...sharedOptions,
});

export const reserveerApi = new ReserveerApi({
	baseURL: config.reserveer.server,
	...sharedOptions,
});

export const clubApi = new ClubApi({
	baseURL: config.club.server,
	...sharedOptions,
});

export const coachApi = new CoachApi({
	baseURL: config.coach.server?.replace(/\/api$/, ""),
	...sharedOptions,
});

export function performForAllApis(
	fn: (api: typeof coreApi | typeof reserveerApi | typeof clubApi | typeof coachApi) => void,
) {
	return [coreApi, reserveerApi, clubApi, coachApi].forEach(fn);
}
