const userMenu = {
	namespaced: true,
	state: {
		opened: "",
	},
	getters: {},
	mutations: {
		open(state, payload) {
			state.opened = payload;
		},
		close(state) {
			state.opened = false;
		},
	},
	actions: {
		openUserMenu({ commit }, payload) {
			commit("open", payload);
		},
		closeUserMenu({ commit }) {
			commit("close");
		},
	},
};

export default userMenu;
