var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),(_vm.coachesWithoutCurrent.length > 0)?[_c('p',[_vm._v(" Wil je de "),_c('strong',[_vm._v(" "+_vm._s(_vm.data.coach.membersCount.total)+" "+_vm._s(_vm.data.coach.membersCount.total === 1 ? "sporter" : "sporters")+" ")]),_vm._v(" van "+_vm._s(_vm.getFullName(_vm.data.coach))+" overdragen aan een andere coach? ")]),_c('div',{staticClass:"select"},[_c('div',{staticClass:"select_option_selected",attrs:{"focusable":"true","tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined)){ return null; }_vm.isOpen = false},"click":function($event){_vm.isOpen = !_vm.isOpen}}},[_vm._v(" "+_vm._s(_vm.selectedCoach ? _vm.getFullName(_vm.selectedCoach) || _vm.selectedCoach.id : "Geen coach")+" "),_c('svg',{class:{ reverse: _vm.isOpen },attrs:{"width":"10","height":"8","viewBox":"0 0 10 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.848 6.6432L9.04375 1.53C9.46003 0.86395 8.98119 -3.9633e-07 8.19575 -3.61669e-07L1.80425 -7.96194e-08C1.01881 -4.4959e-08 0.539969 0.86395 0.956249 1.53L4.152 6.6432C4.54367 7.26987 5.45633 7.26987 5.848 6.6432Z","fill":"black"}})])]),(_vm.isOpen)?_c('div',{staticClass:"select_option-list"},_vm._l((_vm.coachesWithoutCurrent),function(coach){return _c('button',{key:coach.id,staticClass:"select_option-list_option",on:{"click":function () {
							_vm.selectedCoach = coach;
							_vm.isOpen = false;
						}}},[_c('div',{staticClass:"grid flex-1 justify-between"},[_c('div',{staticClass:"user"},[_c('img',{staticClass:"pf",attrs:{"src":coach.imageUrl
										? coach.imageUrl
										: ("https://ui-avatars.com/api/?name=" + (_vm.getFullName(coach))),"width":"35","height":"35"}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.getFullName(coach) || coach.id))])]),_c('div',{staticClass:"flex items-center justify-end gap-2"},[_c('span',{staticClass:"text-sm opacity-75"},[_vm._v("Sporters:")]),_c('span',{staticClass:"text-left tabular-nums",style:({ minWidth: '1.25rem' })},[_vm._v(" "+_vm._s(_vm.data.coach.membersCount.total)+" ")])])])])}),0):_vm._e()])]:_c('p',[_vm._v(" Er zijn geen andere coaches beschikbaar om de sporters van "+_vm._s(_vm.getFullName(_vm.data.coach))+" aan over te dragen. De leden van deze coach zullen zonder coach komen te staan. Locatie: "+_vm._s(_vm.data.gymId)+" ")]),_c('div',{class:{
			invisible: _vm.isOpen,
			actions: true,
		}},[_c('button',{staticClass:"button v_ghost_brand_primary v_has-icon-right v_smaller a_margin-bottom-0 a_margin-top-10",on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticClass:"button-txt"},[_vm._v("Sluiten")])]),(_vm.coachesWithoutCurrent.length > 0)?_c('button',{staticClass:"button v_brand_secondary v_has-icon-right v_smaller a_margin-bottom-0 a_margin-top-10",attrs:{"disabled":_vm.selectedCoach === null},on:{"click":_vm.submit}},[_c('span',{staticClass:"button-txt"},[_vm._v("Toewijzen")])]):_vm._e()])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"pfg-header"},[_c('div',{staticClass:"pfg-header_heading"},[_c('h1',{staticClass:"s_elem-has-icon-left"},[_vm._v("Coach verwijderen")])])])}]

export { render, staticRenderFns }