













































































































import { type PropType, computed, defineComponent, ref } from "@vue/composition-api";
import { useMutation, useQuery } from "@tanstack/vue-query";
import { clubApi, coreApi } from "@/lib/backend";
import type { CoachViewModel } from "@/lib/backend/core.api";
import { getFullName } from "@/lib/getFullName";
import { toast } from "@/lib/vue2-sonner";
import { logger } from "@/logger";

export default defineComponent({
	props: {
		data: {
			type: Object as PropType<{
				coach: CoachViewModel;
				gymId: number;
				callback?: VoidFunction;
			}>,
			required: true,
		},
	},
	emits: {
		close() {
			return true;
		},
	},
	setup(props, { emit }) {
		const isOpen = ref(false);
		const selectedCoach = ref<CoachViewModel | null>(null);

		const mutation = useMutation({
			mutationKey: ["coaches", props.data.gymId, "delete"] as const,
			mutationFn: async (variables: { coachId: number; replacementCoachId: number }) =>
				await clubApi.coach.coachVerwijderen(variables.coachId, {
					coachId: variables.replacementCoachId,
				}),
		});

		const { data: coaches } = useQuery({
			queryKey: ["coaches", props.data.gymId] as const,
			queryFn: async (context) =>
				await coreApi.coaches
					.coachControllerFindAll({ gymId: context.queryKey[1] }, { signal: context.signal })
					.then((response) => response.data.data),
			select: (data) => data.sort((a, b) => getFullName(a).localeCompare(getFullName(b))),
			initialData: [] as Awaited<
				ReturnType<typeof coreApi.coaches.coachControllerFindAll>
			>["data"]["data"],
		});

		const coachesWithoutCurrent = computed(() =>
			coaches.value.filter((coach) => coach.id !== props.data.coach.id),
		);

		async function submit() {
			try {
				if (!props.data.coach.id || !selectedCoach.value?.id) {
					throw new Error("No coach id and/or replacement coach id provided");
				}

				await mutation.mutateAsync({
					coachId: props.data.coach.id,
					replacementCoachId: selectedCoach.value.id,
				});

				toast.success("Succesvol", { description: "De sporters zijn overgedragen" });

				props.data.callback?.();
				emit("close");
			} catch (error) {
				logger.getLogger("ModalAssignMembers").error("submit", error);
				toast.error("Er ging iets mis", {
					description: error instanceof Error ? error.message : JSON.stringify(error),
				});
				throw error;
			}
		}

		return { getFullName, isOpen, selectedCoach, coaches, coachesWithoutCurrent, submit };
	},
});
