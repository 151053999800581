<template>
	<div>
		<div v-show="opened" class="pg_user_container">
			<user></user>
		</div>

		<transition name="open">
			<div v-show="opened" class="pg_user-menu">
				<user-menu-links v-if="opened == 'links'" @close="closeUserMenu"></user-menu-links>
			</div>
		</transition>

		<transition name="fade">
			<div v-show="opened" class="pg_user-menu_bg" @click="closeUserMenu"></div>
		</transition>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import User from "../elements/User";
import UserMenuLinks from "./UserMenuLinks";

export default {
	name: "UserMenu",
	components: {
		User,
		UserMenuLinks,
	},
	computed: {
		...mapState("userMenu", ["opened"]),
	},
	methods: {
		...mapActions("userMenu", ["closeUserMenu"]),
	},
};
</script>

<style lang="scss" scoped>
////
////    VUE TRANSITIONS
////

// open
.open-enter-active {
	transition: all 0.2s ease;
	transform-origin: top right;
}
.open-leave-active {
	transform-origin: top right;
	transition:
		transform 0.2s ease,
		opacity 0.1s ease 0.1s;
}
.open-enter {
	opacity: 0;
	transform: scale(0.5);
}
.open-enter-to,
.open-leave {
	opacity: 1;
	transform: scale(1);
}
.open-leave-to {
	opacity: 0;
	transform: scale(0.5);
}

// fade
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}
.fade-enter,
.fade-leave-to {
	transition: opacity 0.2s ease 0.1s;
	opacity: 0;
}

////
////    GENETRICS
////
.pg_user-menu {
	$user-menu-size: 500px;

	position: fixed;
	z-index: 5556;
	top: 0;
	right: 0;
	width: $user-menu-size;
	height: $user-menu-size;
	background: $brand-white;
	box-shadow: 0 0 30px rgba(black, 0.3);
	border-bottom-left-radius: calc($user-menu-size / 2);
	border-bottom-right-radius: calc($user-menu-size / 2);
	border-top-left-radius: calc($user-menu-size / 2);
}

////
////    BG
////
.pg_user-menu_bg {
	position: fixed;
	z-index: 5555;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: rgba($brand-primary, 0.9);
}

////
////    USER
////
.pg_user_container {
	position: fixed;
	z-index: 5557;
	height: $pg_top-bar_height;
	top: 0;
	right: 0;
	padding: $default-padding;
	display: flex;
	flex-direction: column;
	justify-content: center;
	@include breakpoint(medium) {
		height: $pg_top-bar_height_medium;
	}
}
</style>
