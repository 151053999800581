import config from "@/config";
import clubAuth from "@/lib/club-auth";
import { logger } from "@/logger";

const http = clubAuth.http;

const groepsles = {
	namespaced: true,
	state: {
		groepslessen: [],
		groepsles: {},
		groepslesPlanning: {},
		groepslesPlanningPerLes: {},
	},

	getters: {
		groepslesPlanningen(state) {
			return state.groepslessen.map((groepsles) => {
				return {
					...groepsles,
					planning: state.groepslesPlanning[groepsles.id] || {
						aantalGepland: "-",
						lesType: "-",
						bezet: "-",
						bezettingsPercentage: "-",
						aantalVoltooid: "-",
						bezettingVergelijking: "-",
					},
				};
			});
		},
	},

	mutations: {
		clearGroepslesPlanning(state) {
			state.groepslesPlanning = {};
			state.groepslesPlanningPerLes = [];
		},
		setGroepslessen(state, payload) {
			state.groepslessen = payload;
		},
		setGroepsles(state, payload) {
			state.groepsles = payload;
		},
		setGroepslesPlanning(state, groepslesPlanning) {
			const { bezettingsPercentage } = groepslesPlanning;

			if (bezettingsPercentage && bezettingsPercentage % 1 !== 0) {
				groepslesPlanning.bezettingsPercentage = bezettingsPercentage.toFixed(1);
			}

			groepslesPlanning.bezettingsPercentage += "%";

			state.groepslesPlanning = {
				...state.groepslesPlanning,
				[groepslesPlanning.id]: groepslesPlanning,
			};
		},
		setGroepslesBezettingPerLes(state, { id, data }) {
			let planning = [];
			if (data.length) {
				data.forEach((item) => {
					const { dag, lesType, tijd, vestiging, coach, bezettingsPercentage } = item;

					let bezetting = bezettingsPercentage.toString();
					if (!bezetting.includes("%")) {
						bezetting += "%";
					}

					planning.push([dag, lesType, tijd, vestiging, coach, bezetting]);
				});
			}
			state.groepslesPlanningPerLes = {
				...state.groepslesPlanningPerLes,
				[id]: planning,
			};
		},
	},

	actions: {
		getGroepslessen({ commit }) {
			return http
				.get(`${config.reserveer.server}/groepslessen`)
				.then((response) => {
					if (response.status === 200) {
						commit("setGroepslessen", response.data);
					}
				})
				.catch((err) => {
					logger.error(err.response);
				});
		},
		getGroepsles({ commit }, payload) {
			http
				.get(`${config.reserveer.server}/groepslessen/${payload}`)
				.then((response) => {
					if (response.status === 200) {
						if (response.data.beschrijving) {
							response.data.beschrijving = response.data.beschrijving.split("&nbsp;").join("");
						}
						commit("setGroepsles", response.data);
					}
				})
				.catch((err) => {
					logger.error(err.response);
				});
		},
		async getGroepslesBezetting(_, payload) {
			try {
				const response = await http.get(`${config.club.server}/groepslessen/${payload}/bezetting`);
				return response;
			} catch (err) {
				logger.error(err.response);
			}
		},
		async getGroepslesBezettingPerLes(_, payload) {
			try {
				const response = await http.get(
					`${config.club.server}/groepslessen/${payload}/bezettingperles`,
				);
				return response;
			} catch (err) {
				logger.error(err.response);
			}
		},
		async getGroepslesPlanning({ commit }, groepslesId) {
			try {
				const response = await http.get(
					`${config.club.server}/groepslessen/${groepslesId}/statistieken`,
				);
				commit("setGroepslesPlanning", response.data);
			} catch (error) {
				logger.error(error);
			}
		},
	},
};

export default groepsles;
