import { logger } from "@/logger";

const overlay = {
	namespaced: true,
	state: {
		opened: "",
		overlayData: {},
	},
	getters: {},
	mutations: {
		open(state, payload) {
			state.opened = payload;
		},
		close(state) {
			state.opened = false;
		},
		setOverlayData(state, payload) {
			logger.log("set overlay data", payload.id);
			state.overlayData = payload;
		},
	},
	actions: {
		openOverlay({ commit }, payload) {
			if (typeof payload === "string") {
				commit("open", payload);
			} else {
				// if object
				commit("open", payload.name);
				commit("setOverlayData", payload.data);
			}
		},
		closeOverlay({ commit }) {
			commit("close");
		},
	},
};

export default overlay;
