<template>
	<div>
		<transition name="drop">
			<div v-show="opened" class="pg_overlay">
				<div class="pg_overlay_outer_container">
					<div class="pg_overlay_container">
						<transition name="fade">
							<overlay-groepsles-inplannen
								v-if="opened == 'groepsles-inplannen'"
								:data="overlayData"
								@close="closeOverlay"
							/>
							<overlay-coach-moment-create
								v-else-if="opened === 'coach-moment_create'"
								:data="overlayData"
								@close="closeOverlay"
							/>
							<overlay-coach-moment-update
								v-else-if="opened === 'coach-moment_update'"
								:data="overlayData"
								@close="closeOverlay"
							/>
						</transition>
					</div>
				</div>
			</div>
		</transition>

		<transition name="fade">
			<div v-show="opened" class="pg_overlay_bg" @click="closeOverlay"></div>
		</transition>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import OverlayCoachMomentCreate from "./Overlay/CoachMomentCreate.vue";
import OverlayCoachMomentUpdate from "./Overlay/CoachMomentUpdate.vue";
import OverlayGroepslesInplannen from "./Overlay/GroepslesInplannen.vue";

export default {
	name: "Overlay",
	components: {
		OverlayGroepslesInplannen,
		OverlayCoachMomentCreate,
		OverlayCoachMomentUpdate,
	},
	computed: {
		...mapState("overlay", ["opened", "overlayData"]),
	},
	watch: {
		opened(val) {
			if (val) {
				document.body.style.overflow = "hidden";
				return;
			}
			document.body.style.removeProperty("overflow");
		},
	},
	methods: {
		...mapActions("overlay", ["closeOverlay"]),
	},
};
</script>

<style lang="scss" scoped>
////
////    VUE TRANSITIONS
////

.drop-enter-active {
	transition: all 0.2s ease;
}
.drop-leave-active {
	transition: all 0.2s ease;
}
.drop-enter {
	opacity: 0;
	transform: translateY(-40px);
}
.drop-enter-to,
.drop-leave {
	opacity: 1;
	transform: translateY(0);
}
.drop-leave-to {
	opacity: 0;
	transform: translateY(-40px);
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}
.fade-enter,
.fade-leave-to {
	transition: opacity 0.2s ease 0.1s;
	opacity: 0;
}

////
////    GENETRICS
////
.pg_overlay {
	position: fixed;
	z-index: 5556;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	-webkit-overflow-scrolling: touch;
	overflow-y: scroll;
	// padding: $default-padding * 2;
	background: $brand-white;
}
.pg_overlay_outer_container {
	width: 100%;
	max-width: $max-content-1200;
	margin: 0 auto;
	background: $brand-white;
	// border-radius: 15px;
	// box-shadow: 0 0 30px rgba(black, .3);
	padding: $default-padding * 2;
}
.pg_overlay_container {
}

////
////    BG
////
.pg_overlay_bg {
	position: fixed;
	z-index: 5555;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: rgba($brand-primary, 0.9);
}
</style>
