import clubAuth from "@/lib/club-auth";
import { logger } from "@/logger";

// import store from 'store';

const http = clubAuth.http;

logger.log("club auth initiated");
const auth = {
	namespaced: true,
	state: {
		userInfo: {
			vestiging: undefined,
			admin: false,
		},
	},
	mutations: {
		setUserInfo(state, payload) {
			state.userInfo = payload;
		},
	},
	actions: {
		async authorize(_, payload) {
			const result = clubAuth.authorize(payload);
			return result;
		},
		deauthorize() {
			return clubAuth.deauthorize();
		},
		async info({ commit }) {
			let userInfoXHR = await http.get("/ingelogd");

			commit("setUserInfo", userInfoXHR.data);
			return userInfoXHR.data;
		},
	},
};

export default auth;
