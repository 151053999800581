<template>
	<div>
		<button
			type="button"
			class="button v_transparent_body v_is-icon v_icon-size-16 a_margin-left-20 a_no-padding a_fill_brand_secondary"
			:data-toggle="id"
		>
			<icon :icon-id="'icon_info'"></icon>
		</button>
		<div :id="id" class="dropdown-pane" data-dropdown data-hover="true" data-close-on-click="true">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "InfoDropdown",
	data() {
		return {
			infoDropdown: "",
			id: `infoDropdown-${this._uid}`,
		};
	},
	mounted() {
		this.infoDropdown = new Foundation.Dropdown($(`#${this.id}`), {
			// vOffset: 20,
		});
	},
	destroyed() {
		this.infoDropdown.destroy();
	},
};
</script>

<style lang="scss" scoped>
@include foundation-dropdown;
</style>
