<template>
	<section class="pg_content">
		<div class="pg_content_container a_max-width-1200">
			<div class="pg_content_1-1">
				<header class="pg_header">
					<div class="pg_header_heading">
						<h1 class="s_elem-has-icon-left a_fill_default">
							{{ groepsles.naam }} les
							{{ data.editMode ? "aanpassen" : "inplannen" }}
							<icon :icon-id="'icon_groepsles'"></icon>
						</h1>
					</div>
					<div class="pg_header_description a_margin-top-20">
						<p>
							Geef hieronder aan op welke dag en tijd de groepsles plaats moet vinden, én of je deze
							groepsles wil herhalen de komende tijd.
						</p>
					</div>
				</header>

				<hr />
				<form @submit.prevent="save">
					<div>
						<div class="a_flex">
							<label class="a_margin-right-20" data-form-label="dag"
								>Dag
								<select v-model="dag" v-validate="'required'" name="dag" data-form="dag">
									<option v-for="day in days" :key="`overlay-planning-day-${day}`" :value="day">
										{{ day }}
									</option>
								</select>
								<span v-show="errors.has('dag')">{{ errors.first("dag") }}</span>
							</label>
							<label class="a_margin-right-20" data-form-label="van-uur">
								Van (uur)
								<input
									v-model="tijdUur"
									v-validate="{
										required: ['date_format:HH', 'date_format:H'],
										min_value: 7,
										max_value: 22,
									}"
									name="tijdUur"
									type="text"
									placeholder="9"
									data-form="van-uur"
								/>
								<span v-show="errors.has('tijdUur')">{{ errors.first("tijdUur") }}</span>
							</label>
							<label class="a_margin-right-20" data-form-label="van-minuten">
								(minuut)
								<input
									v-model="tijdMinuut"
									v-validate="{ required: ['date_format:mm', 'date_format:m'] }"
									name="tijdMinuut"
									type="text"
									placeholder="45"
									data-form="van-minuten"
								/>
								<span v-show="errors.has('tijdMinuut')">{{ errors.first("tijdMinuut") }}</span>
							</label>
							<label class="a_margin-right-20" data-form-label="tot-uur">
								Tot (uur)
								<input
									v-model="eindtijdUur"
									v-validate="{
										required: ['date_format:HH', 'date_format:H'],
										min_value: tijdUur || 7,
										max_value: 23,
									}"
									name="eindtijdUur"
									type="text"
									placeholder="10"
									data-form="tot-uur"
								/>
								<span v-show="errors.has('eindtijdUur')">{{ errors.first("eindtijdUur") }}</span>
							</label>
							<label class="a_margin-right-20" data-form-label="tot-minuten">
								(minuut)
								<input
									v-model="eindtijdMinuut"
									v-validate="{
										required: ['date_format:mm', 'date_format:m'],
										min_value: tijdUur === eindtijdUur ? tijdMinuut : 0,
									}"
									name="eindtijdMinuut"
									type="text"
									placeholder="30"
									data-form="tot-minuten"
								/>
								<span v-show="errors.has('eindtijdMinuut')">{{
									errors.first("eindtijdMinuut")
								}}</span>
							</label>
							<infoDropdown> Geef de dag en tijd waarop de les plaats vindt. </infoDropdown>
						</div>
						<span v-show="timeError" class="pg_time-error">{{
							"Let op: De begintijd kan niet gelijk zijn aan de eindtijd."
						}}</span>
					</div>

					<hr />

					<div class="a_flex a_justify_space-between">
						<div class="pg_radio_group_wrapper a_flex a_justify_space-between">
							<div class="pg_radio_group a_margin-top-20">
								<div class="pg_radio_group_container">
									<label>Periode:</label>
									<div class="a_flex pg_radio_group_period">
										<input
											id="herhaal-les-herhaal"
											v-model="duurType"
											value="oneindig"
											type="radio"
											name="herhaal-les"
											data-form="herhaal"
										/>
										<label
											class="a_margin-right-20"
											data-form-label="herhaal-les-herhaal"
											for="herhaal-les-herhaal"
										>
											Herhaal oneindig
										</label>
										<input
											id="herhaal-les-wekelijks"
											v-model="duurType"
											value="aantal"
											type="radio"
											name="herhaal-les"
											data-form="herhaal-wekelijks"
										/>
										<label
											class="a_margin-right-20"
											data-form-label="herhaal-les-wekelijks"
											for="herhaal-les-wekelijks"
										>
											Periodiek
										</label>
									</div>
								</div>

								<div v-if="duurType === 'aantal'" class="a_flex">
									<label for="start-week" class="a_margin-right-20">
										startweek (huidige week: {{ currentWeek }})
										<input
											id="start-week"
											v-model="startWeek"
											v-validate="'required|max_value:53|min_value:1'"
											class="a_margin-bottom-0"
											type="number"
											name="startweek"
										/>
										<span v-show="errors.has('startweek')">{{ errors.first("startweek") }}</span>
									</label>
									<!--<input @change="periodDateChange($event, 'startWeek')" type="date">-->

									<label for="eindweek-week">
										eindweek
										<input
											id="eind-week"
											v-model="endWeek"
											v-validate="'required|max_value:53|min_value:1'"
											class="a_margin-bottom-0"
											name="endweek"
											:disabled="duurType !== 'aantal'"
											type="number"
										/>
										<span v-show="errors.has('endweek')">{{ errors.first("endweek") }}</span>
									</label>
									<!--<input @change="periodDateChange($event, 'endWeek')" ref="endWeekInput" type="date">-->
								</div>
							</div>
							<div class="pg_radio_group a_margin-top-20">
								<label>Les type:</label>
								<button
									type="button"
									class="a_no-margin button v_smallest v_has-icon-right v_icon-size-16"
									:class="itemType === 'live' ? 'v_brand_primary' : 'v_ghost_brand_primary'"
									@click="itemType = 'live'"
								>
									<span>Live</span>
									<icon :icon-id="'icon_live'"></icon>
								</button>
								<button
									type="button"
									class="a_no-margin a_margin-left-10 button v_smallest v_has-icon-right v_icon-size-18"
									:class="itemType === 'virtual' ? 'v_brand_primary' : 'v_ghost_brand_primary'"
									@click="itemType = 'virtual'"
								>
									<span>Virtueel</span>
									<icon :icon-id="'icon_virtual'"></icon>
								</button>
								<button
									type="button"
									class="a_no-margin a_margin-left-10 button v_smallest v_has-icon-right v_icon-size-20"
									:class="itemType === 'buiten' ? 'v_brand_primary' : 'v_ghost_brand_primary'"
									@click="itemType = 'buiten'"
								>
									<span>Buiten</span>
									<icon :icon-id="'icon_outside'"></icon>
								</button>
							</div>
						</div>
					</div>

					<hr />
					<div class="a_flex a_justify_space-between">
						<div class="a_flex">
							<label class="a_margin-right-20" data-form-label="vestiging"
								>Vestiging
								<select
									v-model="vestigingId"
									v-validate="'required'"
									name="vestigingId"
									data-form="vestiging"
								>
									<option
										v-for="vestiging in vestigingen"
										:key="`overlay-planning-vestiging-${vestiging.id}`"
										:value="vestiging.id"
									>
										{{ vestiging.naam }}
									</option>
								</select>
								<span v-show="errors.has('vestigingId')">{{ errors.first("vestigingId") }}</span>
							</label>
							<label class="a_margin-right-20" data-form-label="zaal"
								>Zaal
								<select v-model="zaalId" v-validate="'required'" name="zaalId" data-form="zaal">
									<option
										v-for="zaal in vestigingZalen"
										:key="`overlay-planning-zaal-${zaal.id}`"
										:value="zaal.id"
									>
										{{ zaal.naam }}
									</option>
								</select>
								<span v-show="errors.has('zaalId')">{{ errors.first("zaalId") }}</span>
							</label>
							<label class="a_margin-right-20" data-form-label="plekken">
								Deelnemers
								<input
									v-model="plekken"
									v-validate="'required'"
									name="plekken"
									type="number"
									placeholder="0"
									data-form="plekken"
								/>
								<span v-show="errors.has('plekken')">{{ errors.first("plekken") }}</span>
							</label>
						</div>
						<div>
							<div class="a_flex a_justify_flex-end">
								<div v-if="selectedTrainer" class="pg_avatar v_smaller a_margin-right-10">
									<figure
										class="pg_avatar_image"
										:style="`background-image: url(${
											selectedTrainer.foto || 'static/img/trainers/avatar-placeholder.png'
										}`"
									></figure>
								</div>
								<label data-form-label="coach"
									>Aangewezen coach
									<select
										v-model="trainerId"
										v-validate="'required'"
										name="trainerId"
										data-form="coach"
									>
										<option value="groepsles-trainers" disabled="disabled">
											Groepsles trainers
										</option>
										<option
											v-for="trainer in groepslesTrainers"
											:key="trainer.id"
											:value="trainer.id"
										>
											{{ trainer.naam }}
										</option>
										<option value="overige-trainers" disabled="disabled">Overige trainers</option>
										<option v-for="trainer in otherTrainers" :key="trainer.id" :value="trainer.id">
											{{ trainer.naam }}
										</option>
									</select>
									<span v-show="errors.has('trainerId')">{{ errors.first("trainerId") }}</span>
								</label>
								<infoDropdown>
									Indien de gewenste coach er niet tussen staat, zorg er dan voor dat de les "{{
										groepsles.naam
									}}" is toegekend aan de coach binnen de Wordpress omgeving
								</infoDropdown>
							</div>
						</div>
					</div>

					<div v-if="error.message" class="pg_feedback-message s_error a_margin-top-40">
						{{ error.message }}
					</div>

					<hr />

					<div class="a_flex a_justify_flex-end">
						<button
							type="button"
							class="button v_ghost_brand_secondary v_has-icon-right v_smaller a_margin-left-10"
							@click="$emit('close')"
						>
							Annuleren
						</button>
						<button
							type="submit"
							class="button v_ghost_brand_primary v_has-icon-right v_smaller a_margin-left-10"
						>
							Opslaan
							<icon :icon-id="'icon_arrow-right'"></icon>
						</button>
					</div>
				</form>
			</div>
		</div>
	</section>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import InfoDropdown from "@/components/elements/InfoDropdown.vue";
import config from "@/config";
import { logger } from "@/logger";

export default {
	name: "OverlayGroepslesInplannen",
	components: {
		InfoDropdown,
	},
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			timeError: false,
			itemType: "live",
			vestigingId: "",
			zaalId: "",
			plekken: 0,
			duurType: "oneindig",
			tijd: "",
			tijdUur: "",
			tijdMinuut: "",
			eindTijd: "",
			eindtijdUur: "",
			eindtijdMinuut: "",
			trainerId: "",
			days: ["maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag", "zondag"],
			startWeek: "",
			endWeek: "",
			dag: "",
			roosterLesId: "",
			// API response error
			error: {
				message: "",
				conflicterendeParen: [],
			},
		};
	},
	computed: {
		...mapState("trainer", ["trainers"]),
		...mapState("groepsles", ["groepsles"]),
		...mapState("vestiging", ["vestigingen"]),
		...mapState("rooster", ["rooster"]),
		groepslesTrainers() {
			if (!this.trainers.length) return [];

			return this.trainers
				.filter((trainer) => {
					if (!trainer.groepslessenIds) return false;

					if (trainer.groepslessenIds.indexOf(this.groepsles.id) !== -1) {
						return true;
					}
					return false;
				})
				.sort((a, b) => {
					if (a.naam > b.naam) {
						return 1;
					} else if (a.naam < b.naam) {
						return -1;
					}
					return 0;
				});
		},
		otherTrainers() {
			return this.trainers
				.filter((trainer) => {
					if (!trainer.groepslessenIds) return false;

					if (trainer.groepslessenIds.indexOf(this.groepsles.id) === -1) {
						return true;
					}
					return false;
				})
				.sort((a, b) => {
					if (a.naam > b.naam) {
						return 1;
					} else if (a.naam < b.naam) {
						return -1;
					}
					return 0;
				});
		},
		vestigingZalen() {
			if (!this.vestigingId) return [];
			return this.vestigingen.find((vestiging) => {
				if (vestiging.id === this.vestigingId) return true;
				return false;
			}).zalen;
		},
		currentWeek() {
			return moment().week();
		},
		avatarImage() {
			return "static/img/trainers/avatar-placeholder.png";
		},
		coachServer() {
			return config.coach.server;
		},
		selectedTrainer() {
			if (this.trainerId) {
				const trainer = this.trainers.find((trainer) => trainer.id === this.trainerId);
				return trainer;
			}

			return undefined;
		},
	},
	watch: {
		eindtijdUur() {
			this.timeError = false;
		},
		eindtijdMinuut() {
			this.timeError = false;
		},
		tijdUur() {
			this.timeError = false;
		},
		tijdMinuut() {
			this.timeError = false;
		},
	},
	created() {
		this.getTrainers();
		if (this.data.editMode) {
			this.vestigingId = this.data.les.vestigingId;
			this.zaalId = this.data.les.zaalId;
			this.plekken = this.data.les.plekken;
			this.tijd = this.data.les.tijd;
			this.tijdUur = this.data.les.tijd.split(":")[0];
			this.tijdMinuut = this.data.les.tijd.split(":")[1];
			this.eindtijd = this.getEndTime(this.data.les.tijd, this.data.les.duur);
			this.eindtijdUur = this.eindtijd.split(":")[0];
			this.eindtijdMinuut = this.eindtijd.split(":")[1];
			this.trainerId = this.data.les.trainerId;
			this.dag = this.data.les.dag;
			this.itemType = this.data.les.itemType;
			if (this.data.les.range && this.data.les.range.startWeek) {
				this.startWeek = this.data.les.range.startWeek;
			}
			if (this.data.les.range && this.data.les.range.eindWeek) {
				this.endWeek = this.data.les.range.eindWeek;
			}
			if (this.endWeek) {
				this.duurType = "aantal";
			}
			this.roosterLesId = this.data.les.id;
		}
	},
	methods: {
		...mapActions("trainer", ["getTrainers"]),
		...mapActions("rooster", ["addLes"]),
		save() {
			if (
				parseInt(this.tijdUur) === parseInt(this.eindtijdUur) &&
				parseInt(this.tijdMinuut) === parseInt(this.eindtijdMinuut)
			) {
				this.timeError = true;
				return;
			}

			this.$validator
				.validateAll()
				.then((valid) => {
					if (valid) {
						const les = this.processSubmitData();
						this.addLes(les)
							.then(() => {
								this.$emit("close");
							})
							.catch((err) => {
								if (err && err.response && err.response.data) {
									this.error = err.response.data;
								}
								logger.log(err, err.response);
							});
					}
				})
				.catch(logger.error);
		},
		processSubmitData() {
			const tijdUur = parseInt(this.tijdUur.toString(), 10);
			const eindtijdUur = parseInt(this.eindtijdUur.toString(), 10);
			const tijdMinuut =
				this.tijdMinuut.toString().length === 1 ? `0${this.tijdMinuut}` : this.tijdMinuut;
			const eindtijdMinuut =
				this.eindtijdMinuut.toString().length === 1
					? `0${this.eindtijdMinuut}`
					: this.eindtijdMinuut;
			const lesMinuten = this.lessonLength(
				`${tijdUur}:${tijdMinuut}`,
				`${eindtijdUur}:${eindtijdMinuut}`,
			);

			const les = {
				id: this.rooster.id,
				roosterItems: [
					{
						itemType: this.itemType,
						groepslesId: this.groepsles.id,
						trainerId: this.trainerId,
						vestigingId: this.vestigingId,
						zaalId: this.zaalId,
						plekken: +this.plekken,
						dag: this.dag,
						tijd: `${this.tijdUur}:${this.tijdMinuut}`,
						duur: lesMinuten,
						range: {
							startWeek: +this.startWeek,
							eindWeek: +this.endWeek,
						},
					},
				],
			};

			if (this.duurType === "oneindig") {
				delete les.roosterItems[0].range;
			}
			if (this.roosterLesId) {
				les.roosterItems[0].id = this.roosterLesId;
			}

			return les;
		},
		getEndTime(startTime, duration) {
			return moment(startTime, "H:mm").add(duration, "minutes").format("H:mm");
		},
		lessonLength(startTime, endTime) {
			return moment.duration(moment(endTime, "H:mm").diff(moment(startTime, "H:mm"))).asMinutes();
		},
		periodDateChange(e, type) {
			this[type] = moment(e.target.valueAsDate).week();
		},
		getImage(trainerId) {
			const selectedTrainer = this.trainers.find((trainer) => trainer.id === trainerId);
			if (selectedTrainer) {
				logger.log(selectedTrainer);
			}
		},
	},
};
</script>

<style scoped lang="scss">
.pg_feedback-message.s_error,
.pg_time-error {
	color: $brand-secondary;
}

////
////    FORM SPECIFIC
////
label {
	display: block;
}

.pg_radio_group_wrapper {
	flex-grow: 1;
}

.pg_radio_group {
	display: inline-flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 20px;
	> label {
		width: 100%;
	}
	input {
		margin: 0;
	}
	&_container {
		display: flex;
		flex-direction: column;
		height: 100%;
	}
	&_period {
		flex-grow: 1;
		align-items: center;
	}
	.button {
		height: 40px;
		display: flex;
		align-items: center;
		> span:not(.svg-container) {
			transform: translateY(1px);
		}
	}
}

[data-form="datum"] {
	min-width: 140px;
}

[data-form="van-uur"],
[data-form="van-minuten"] {
	min-width: 100px;
}

[data-form="tot-uur"],
[data-form="tot-minuten"] {
	min-width: 100px;
}

[data-form="dag"] {
	min-width: 200px;
}

[data-form="plekken"] {
	width: 60px;
}

[data-form="herhaal-aantal"] {
	width: 50px;
}
[data-form-label="van-uur"] {
	margin-left: 2em;
}
[data-form-label="tot-uur"] {
	margin-left: 2em;
}
[data-form-label="coach"] {
	text-align: right;
	max-width: 220px;
}

[data-form="coach"] {
	max-width: 220px;
}
</style>
