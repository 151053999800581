export function shouldShowPeriodPicker(routeName) {
	const isSettingsRoute = /^settings/.test(routeName);
	if (["home", "groepsles-detail", "groepsles-planning", "fitness-blokken"].includes(routeName)) {
		return false;
	}
	if (isSettingsRoute) {
		return false;
	}
	return true;
}
