export const NODE_ENV = process.env.NODE_ENV;
export const LOGGING = process.env.VUE_APP_LOGGING === "true" ? true : false;
export const GOOGLE_ANALYTICS = process.env.VUE_APP_GOOGLE_ANALYTICS;
export const API_SERVER_COACH = process.env.VUE_APP_API_SERVER_COACH;
export const API_SERVER_CLUB = process.env.VUE_APP_API_SERVER_CLUB;
export const API_SERVER_RESERVEER = process.env.VUE_APP_API_SERVER_RESERVEER;
export const API_CLIENT_ID = process.env.VUE_APP_API_CLIENT_ID;
export const API_CLIENT_SECRET = process.env.VUE_APP_API_CLIENT_SECRET;
export const APPLICATION_NAME = process.env.APPLICATION_NAME;
export const APPLICATION_VERSION = process.env.APPLICATION_VERSION;
export const APPLICATION_ENVIRONMENT = process.env.APPLICATION_ENVIRONMENT;
export const APPLICATION_COMMIT_HASH = process.env.APPLICATION_COMMIT_HASH;
export const APPLICATION_TIME_OF_BUILD = process.env.APPLICATION_TIME_OF_BUILD;
