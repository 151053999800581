<template>
	<div>
		<transition name="drop">
			<div v-show="opened" class="pg_modal">
				<div class="pg_modal_container">
					<modal-groepsles-verwijderen
						v-if="opened == 'groepsles-verwijderen'"
						:data="modalData"
						@close="closeModal"
					></modal-groepsles-verwijderen>
					<modal-rooster-publiceren
						v-if="opened == 'rooster-publiceren'"
						:data="modalData"
						@close="closeModal"
					/>
					<modal-meld-probleem
						v-if="opened == 'meld-probleem'"
						:data="modalData"
						@close="closeModal"
					></modal-meld-probleem>
					<modal-confirm
						v-if="opened == 'confirm'"
						:data="modalData"
						@close="closeModal"
					></modal-confirm>
					<modal-coach-login
						v-if="opened == 'coach-login'"
						:data="modalData"
						@close="closeModal"
					></modal-coach-login>
					<modal-assign-members
						v-if="opened == 'assign-members'"
						:callback="modalCallbacks['assign-members']"
						:data="modalData"
						@close="closeModal"
					/>
					<modal-coach-moment-delete
						v-else-if="opened === 'coach-moment_delete'"
						:data="modalData"
						@close="closeModal"
					/>
				</div>

				<transition name="fade">
					<div v-show="opened" class="pg_modal_bg" @click="closeModal"></div>
				</transition>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ModalCoachMomentDelete from "./Modal/CoachMomentDelete.vue";
import ModalAssignMembers from "./ModalAssignMembers.vue";
import ModalCoachLogin from "./ModalCoachLogin";
import ModalConfirm from "./ModalConfirm";
import ModalGroepslesVerwijderen from "./ModalGroepslesVerwijderen";
import ModalMeldProbleem from "./ModalMeldProbleem";
import ModalRoosterPubliceren from "./ModalRoosterPubliceren";

export default {
	name: "Modal",
	components: {
		ModalGroepslesVerwijderen,
		ModalRoosterPubliceren,
		ModalMeldProbleem,
		ModalCoachLogin,
		ModalConfirm,
		ModalAssignMembers,
		ModalCoachMomentDelete,
	},
	computed: {
		...mapState("modal", ["opened", "modalData", "modalCallbacks"]),
	},
	methods: {
		...mapActions("modal", ["closeModal"]),
	},
};
</script>

<style lang="scss" scoped>
////
////    VUE TRANSITIONS
////

.drop-enter-active {
	transition: all 0.2s ease;
}
.drop-leave-active {
	transition: all 0.2s ease;
}
.drop-enter {
	opacity: 0;
	transform: translateY(-40px);
}
.drop-enter-to,
.drop-leave {
	opacity: 1;
	transform: translateY(0);
}
.drop-leave-to {
	opacity: 0;
	transform: translateY(-40px);
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s ease;
}
.fade-enter,
.fade-leave-to {
	transition: opacity 0.2s ease 0.1s;
	opacity: 0;
}

////
////    GENETRICS
////
.pg_modal {
	position: fixed;
	z-index: 5556;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.pg_modal_container {
	background: $brand-white;
	box-shadow: 0 0 30px rgba(black, 0.3);
	padding: $default-padding * 2;
	width: $max-content-800;
	border-radius: 15px;
	z-index: 6666;
}

////
////    BG
////
.pg_modal_bg {
	position: fixed;
	z-index: 4444;
	top: 0;
	left: 0;
	width: 100vw;
	height: 110vh;
	background: rgba($brand-primary, 0.9);
}
</style>
