import { defineStore } from "pinia";

type RoosterState = {
	id: number | undefined;
};

export const useRoosterStore = defineStore("rooster", {
	state: (): RoosterState => ({
		id: undefined,
	}),
});
