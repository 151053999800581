





























































import { defineComponent, ref } from "@vue/composition-api";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { reserveerApi } from "@/lib/backend";
import { logger } from "@/logger";
import { useRoosterStore } from "@/pinia/rooster";

export default defineComponent({
	name: "ModalRoosterPubliceren",
	setup() {
		const roosterStore = useRoosterStore();
		const queryClient = useQueryClient();

		const loading = ref(false);
		const closeMessage = ref<string | undefined>(undefined);

		const mutationPublishRooster = useMutation({
			mutationKey: ["rooster", "publiceer"],
			mutationFn: async (variables: { roosterId: number }) =>
				await reserveerApi.roosters
					.publiceer(variables.roosterId)
					.then((response) => response.data),
		});

		async function publish() {
			try {
				loading.value = true;

				if (!roosterStore.id) {
					throw new Error("No `id` within rooster store provided");
				}

				await mutationPublishRooster.mutateAsync({ roosterId: roosterStore.id });

				closeMessage.value = "Het rooster is succesvol gepubliceerd!";
				queryClient.refetchQueries({ queryKey: ["roosters"] });
			} catch (error) {
				logger.error("Failed to publish rooster", error);
				closeMessage.value = `Er is iets fout gegaan probeer het later nog eens of neem contact op met de beheerder: ${error instanceof Error ? error.message : error}`;
			} finally {
				loading.value = false;
			}
		}

		return { loading, closeMessage, publish };
	},
});
