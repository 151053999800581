<template>
	<div>
		<div class="pg_user-menu-links_container">
			<div class="pg_user-menu-links_button">
				<button
					class="button v_transparent v_has-icon-top v_icon-size-30 a_fill_brand_primary-lightest"
					@click="onGoToSettings"
				>
					<span>Instellingen</span>
					<icon :icon-id="'icon_cogwheel'"></icon>
				</button>
			</div>
			<div class="pg_user-menu-links_button">
				<button
					class="button v_transparent v_has-icon-top v_icon-size-30 a_fill_brand_primary-lightest"
					@click="logout"
				>
					<span>Uitloggen</span>
					<icon :icon-id="'icon_afmelden'"></icon>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	name: "Links",
	methods: {
		...mapActions("auth", ["deauthorize"]),
		logout() {
			this.deauthorize();
			// this.$router.push('/login');
			this.$emit("close");
		},
		onGoToSettings() {
			this.$router.push({ name: "settings" });
		},
	},
};
</script>

<style scoped lang="scss">
.pg_user-menu-links_container {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: $default-padding * 2;
}

.pg_user-menu-links_button {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: $default-border;
	padding: $default-padding 0;

	&:last-child {
		border-right: none;
	}
}

.button {
	margin: 0;
	text-transform: uppercase;
	font-size: 0.9rem;
}
</style>
