import Vue from "vue";
import config from "@/config";
import { performForAllApis } from "@/lib/backend";
import clubAuth from "@/lib/club-auth";

const http = clubAuth.http;

const vestiging = {
	namespaced: true,
	state: {
		vestigingen: [],
		vestigingId: {},
	},

	getters: {
		vestiging(state) {
			return state.vestigingen[state.vestigingId];
		},
		getVestigingByName(state) {
			return (name) => {
				return state.vestigingen.find(({ naam }) => naam === name);
			};
		},
		sortedVestigingenByName(state) {
			return [...state.vestigingen].sort((a, b) => {
				if (a.naam > b.naam) {
					return 1;
				}
				if (a.naam < b.naam) {
					return -1;
				}
				return 0;
			});
		},
	},

	mutations: {
		setVestigingen(state, payload) {
			state.vestigingen = payload;
		},
		setVestiging(state, payload) {
			state.vestigingId = payload;
			http.defaults.headers["Gym-Location"] = payload;
			performForAllApis((api) => (api.instance.defaults.headers.common["Gym-Location"] = payload));
		},
	},

	actions: {
		getVestigingen({ state, commit }) {
			if (!state.vestigingen.length) {
				return new Promise((resolve, reject) => {
					http
						.get(`${config.reserveer.server}/vestigingen`)
						.then((response) => {
							if (response.status === 200) {
								commit("setVestigingen", response.data);
								resolve(response.data);
							}
						})
						.catch((err) => {
							reject(err);
						});
				});
			}
		},
		getVestiging({ commit }) {
			const currentVestiging = Vue.localStorage.get("vestigingId");
			if (currentVestiging) {
				commit("setVestiging", currentVestiging);
			}
		},
		setVestiging({ commit }, payload) {
			Vue.localStorage.set("vestigingId", payload);
			commit("setVestiging", payload);
		},
	},
};

export default vestiging;
