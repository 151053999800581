import clubAuth from "@/lib/club-auth";

const module = {
	namespaced: true,
	state: {},
	mutations: {},
	actions: {
		async getVisitsPerHour(_, { vergelijkMet, date }) {
			const route = `dashboard/bezoeken-per-uur/${vergelijkMet}?datum=${date.format("DD-MM-YYYY")}`;
			const res = await clubAuth.http.get(route);
			return res.data;
		},
	},
};

export default module;
