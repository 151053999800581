import config from "@/config";
import clubAuth from "@/lib/club-auth";
import { logger } from "@/logger";

const http = clubAuth.http;

const trainer = {
	namespaced: true,
	state: {
		trainers: [],
		trainer: {},
	},

	getters: {
		getTrainerById(ob, pl) {
			logger.log(ob, pl);
		},
	},

	mutations: {
		setTrainers(state, payload) {
			state.trainers = payload;
		},
		setTrainer(state, payload) {
			state.trainer = payload;
		},
	},

	actions: {
		getTrainers({ commit }) {
			return new Promise((resolve, reject) => {
				http
					.post(`${config.reserveer.server}/trainers`, {})
					.then((response) => {
						if (response.status === 200) {
							commit("setTrainers", response.data);
							resolve();
						}
					})
					.catch((err) => {
						logger.error(err.response);
						reject();
					});
			});
		},
		getTrainer({ commit }, payload) {
			http
				.get(`${config.reserveer.server}/trainer/${payload}`)
				.then((response) => {
					if (response.status === 200) {
						commit("setTrainer", response.data);
					}
				})
				.catch(logger.error);
		},
		getTrainerById({ state }, payload) {
			if (!state.trainers.length) {
				return "";
			}
			const singleTrainer = state.trainers.find((sTrainer) => sTrainer.id === payload);
			if (singleTrainer) {
				logger.log(singleTrainer.naam);
			}
			return (singleTrainer && singleTrainer.naam) || "";
		},
	},
};

export default trainer;
