import clubAuth from "@/lib/club-auth";

const module = {
	namespaced: true,
	state: {},
	mutations: {},
	actions: {
		async getTable(store, periode) {
			const res = await clubAuth.http.get(`/lessen/tabel/${periode}`);
			return res.data;
		},
	},
};

export default module;
