import clubAuth from "@/lib/club-auth";

const http = clubAuth.http;

const settings = {
	namespaced: true,
	state: {
		registrationTargets: [],
		cancellationTargets: [],
	},
	getters: {
		getRegistrationTargetsByLocation(state) {
			return (locationId) => {
				return state.registrationTargets.filter(({ vestigingId }) => vestigingId === locationId);
			};
		},
		getYearlyRegistrationTargetByLocation(state, getters) {
			return (locationId) => {
				return getters.getRegistrationTargetsByLocation(locationId).reduce((sum, { waarde }) => {
					return sum + waarde;
				}, 0);
			};
		},
		getCancellationTargetsByLocation(state) {
			return (locationId) => {
				return state.cancellationTargets.filter(({ vestigingId }) => vestigingId === locationId);
			};
		},
		getYearlyCancellationTargetByLocation(state, getters) {
			return (locationId) => {
				return getters.getCancellationTargetsByLocation(locationId).reduce((sum, { waarde }) => {
					return sum + waarde;
				}, 0);
			};
		},
	},
	mutations: {
		SET_REGISTRATION_TARGETS(state, newRegistrationTargets) {
			state.registrationTargets = newRegistrationTargets;
		},
		UPDATE_REGISTRATION_TARGET(state, updatedRegistrationTarget) {
			state.registrationTargets = state.registrationTargets.reduce(
				(newRegistrationTargets, currentRegistrationTarget) => {
					const { maand, vestigingId } = currentRegistrationTarget;
					if (
						maand === updatedRegistrationTarget.maand &&
						vestigingId === updatedRegistrationTarget.vestigingId
					) {
						newRegistrationTargets.push({
							jaar: updatedRegistrationTarget.jaar,
							maand: updatedRegistrationTarget.maand,
							vestigingId: updatedRegistrationTarget.vestigingId,
							waarde: updatedRegistrationTarget.waarde,
						});
					} else {
						newRegistrationTargets.push(currentRegistrationTarget);
					}
					return newRegistrationTargets;
				},
				[],
			);
		},
		SET_CANCELLATION_TARGETS(state, newCancellationTargets) {
			state.cancellationTargets = newCancellationTargets;
		},
		UPDATE_CANCELLATION_TARGET(state, updatedCancellationTarget) {
			state.cancellationTargets = state.cancellationTargets.reduce(
				(newCancellationTargets, currentCancellationTarget) => {
					const { maand, vestigingId } = currentCancellationTarget;
					if (
						maand === updatedCancellationTarget.maand &&
						vestigingId === updatedCancellationTarget.vestigingId
					) {
						newCancellationTargets.push({
							jaar: updatedCancellationTarget.jaar,
							maand: updatedCancellationTarget.maand,
							vestigingId: updatedCancellationTarget.vestigingId,
							waarde: updatedCancellationTarget.waarde,
						});
					} else {
						newCancellationTargets.push(currentCancellationTarget);
					}
					return newCancellationTargets;
				},
				[],
			);
		},
	},
	actions: {
		async getRegistrationTargetsByYear({ commit }, { year }) {
			const response = await http.get(`/aanmeldingtargets/${year}`);
			commit("SET_REGISTRATION_TARGETS", response.data);
			return response;
		},

		async updateRegistrationTargets({ commit }, registrationTargets) {
			const response = await http.post("/aanmeldingtargets", registrationTargets);
			registrationTargets.forEach((registrationTarget) => {
				commit("UPDATE_REGISTRATION_TARGET", registrationTarget);
			});
			return response;
		},
		async getCancellationTargetsByYear({ commit }, { year }) {
			const response = await http.get(`/afmeldingtargets/${year}`);
			commit("SET_CANCELLATION_TARGETS", response.data);
			return response;
		},
		async updateCancellationTargets({ commit }, cancellationTargets) {
			const response = await http.post("/afmeldingtargets", cancellationTargets);
			cancellationTargets.forEach((cancellationTarget) => {
				commit("UPDATE_CANCELLATION_TARGET", cancellationTarget);
			});
			return response;
		},
	},
};

export default settings;
