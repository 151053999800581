






























































import { type PropType, defineComponent, reactive } from "@vue/composition-api";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { clubApi } from "@/lib/backend";
import type { IomodelsCoachLoginExtern } from "@/lib/backend/club.api";
import type { CoachViewModel } from "@/lib/backend/core.api";
import { labelVariants } from "@/lib/pfg/components/label";
import Label from "@/lib/pfg/vue2/label/Label.vue";
import { toast } from "@/lib/vue2-sonner";
import { logger } from "@/logger";

export default defineComponent({
	components: { Label },
	props: {
		data: {
			type: Object as PropType<{ gymId: number; coach: CoachViewModel }>,
			required: true,
		},
	},
	emits: {
		close() {
			return true;
		},
	},
	setup(props, { emit }) {
		const queryClient = useQueryClient();
		const state = reactive({
			username: "",
			password: {
				current: "",
				confirm: "",
			},
		});

		const mutation = useMutation({
			mutationKey: ["coaches", props.data.gymId, "delete"] as const,
			mutationFn: async (variables: IomodelsCoachLoginExtern) =>
				await clubApi.coachlogin.postCoachLogin(variables),
		});

		async function submit() {
			try {
				if (!state.username || !state.password.current) {
					throw new Error("Geen valide waardes ingevuld");
				}

				if (state.password.current !== state.password.confirm) {
					throw new Error("Wachtwoorden komen niet overeen");
				}

				await mutation.mutateAsync({
					coachId: props.data.coach.id,
					username: state.username,
					password: state.password.current,
					passwordHerhaling: state.password.confirm,
				});

				queryClient.refetchQueries({ queryKey: ["coaches", props.data.gymId] });

				switch (props.data.coach.hasLogin) {
					case true:
						toast.success("Succesvol", { description: "Login voor de coach is aangemaakt" });
						break;
					case false:
					default:
						toast.success("Succesvol", { description: "Login voor de coach is aangepast" });
						break;
				}

				emit("close");
			} catch (error) {
				logger.getLogger("ModalCoachLogin").error("submit", error);
				toast.error("Er ging iets mis", {
					description: error instanceof Error ? error.message : JSON.stringify(error),
				});
				throw error;
			}
		}

		return {
			...props,
			labelVariants,
			state,
			submit,
		};
	},
});
