import _ from "lodash";
import moment from "moment";
import clubAuth from "@/lib/club-auth";

// import moment from 'moment';

function queryStartDate({ opening, periode, index }) {
	const diffType = {
		dag: "days",
		week: "weeks",
		maand: "months",
		jaar: "years",
	}[periode];
	return opening.clone().add(index, diffType).startOf(diffType.slice(0, -1));
}

const module = {
	namespaced: true,
	state: {
		items: [],
		query: {
			opening: {},
			periode: "week",
			vergelijkMet: "vorige-periode",
			index: 1,
			days: 7,
			periodType: "variabel",
		},
	},
	mutations: {
		set(state, payload) {
			state.items = payload;
		},
		add(state, payload) {
			state.items.push(payload);
		},
		remove(state, payload) {
			state.items = state.items.filter((item) => item._id !== payload);
		},
	},
	actions: {
		async getByPeriod(
			{ state },
			{
				opening,
				soort,
				periode,
				vergelijkMet,
				index,
				location,
				overWrittenPeriod,
				overWrittenCompareToMomentObject,
				overWrittenPeriodType,
			},
		) {
			const diffType = {
				dag: "days",
				week: "weeks",
				maand: "months",
				jaar: "years",
			}[periode];
			const date = overWrittenCompareToMomentObject
				? overWrittenCompareToMomentObject
				: opening.clone().add(index, diffType).startOf(diffType.slice(0, -1));
			const periodType = overWrittenPeriodType ? overWrittenPeriodType : state.query.periodType;
			const query =
				periodType === "variabel"
					? `statistieken/${soort}/variabel?vergelijkMet=${vergelijkMet}&dagen=${state.query.days}`
					: `statistieken/${soort}/${
							overWrittenPeriod ? overWrittenPeriod : periode
						}?vergelijkMet=${vergelijkMet}&datum=${date.format("DD-MM-YYYY")}`;
			const res = await clubAuth.http.get(query, {
				headers: location ? { "Gym-Location": location } : {},
			});
			return res.data;
		},
		async getCompareByPeriod({ state }, { opening, soort, periode, vergelijkMet, index }) {
			const diffType = {
				dag: "days",
				week: "weeks",
				maand: "months",
				jaar: "years",
			}[periode];
			const date = opening.clone().add(index, diffType).startOf(diffType.slice(0, -1));
			const query =
				state.query.periodeType === "variabel"
					? `statistieken/${soort}/variabel/${vergelijkMet}?dagen=${state.query.days}`
					: `statistieken/${soort}/${periode}/${vergelijkMet}?datum=${date.format("DD-MM-YYYY")}`;
			const res = await clubAuth.http.get(query);
			return res.data;
		},
		async getGraphData({ state }, { opening, soort, periode, vergelijkMet, index, chartOptions }) {
			periode = periode === "dag" ? "week" : periode;
			if (state.query.periodeType === "variabel") {
				periode = "dag";
			}
			const diffType = {
				dag: "days",
				week: "weeks",
				maand: "months",
				jaar: "years",
			}[periode];

			const date = opening.clone().add(index, diffType).startOf(diffType.slice(0, -1));
			const query =
				state.query.periodType === "variabel"
					? `/statistieken/grafiek/${soort}/variabel/${vergelijkMet}?dagen=${state.query.days}`
					: `/statistieken/grafiek/${soort}/${periode}/${vergelijkMet}?datum=${date.format(
							"DD-MM-YYYY",
						)}`;
			const res = await clubAuth.http.get(query);
			// filling cahrt
			let firstDate = 0;

			if (state.query.periodType === "variabel") {
				firstDate = moment().subtract(state.query.days - 1, "days");
				chartOptions.series[0].data =
					res.data.huidige.length > 0
						? res.data.huidige.map((item) => item.waarde)
						: _.fill(Array(state.query.days), 0);
				chartOptions.series[1].data =
					res.data.vorige.length > 0
						? res.data.vorige.map((item) => item.waarde)
						: _.fill(Array(state.query.days), 0);

				chartOptions.plotOptions = {
					series: {
						pointStart: firstDate.unix() * 1000,
						pointInterval: 24 * 3600 * 1000,
					},
				};
				chartOptions.xAxis.dateTimeLabelFormats.day = "%d-%b";
				chartOptions.tooltip.xDateFormat = "%d-%m-%Y (%A)";
				chartOptions.xAxis.tickInterval = 24 * 3600 * 1000;
			} else if (state.query.periodType === "normal") {
				switch (periode) {
					case "dag": {
						firstDate = date.isoWeekday() - 1;
						chartOptions.series[0].data =
							res.data.huidige.length > 0
								? res.data.huidige.map((item) => item.waarde)
								: _.fill(Array(7), 0);
						chartOptions.series[1].data =
							res.data.vorige.length > 0
								? res.data.vorige.map((item) => item.waarde)
								: _.fill(Array(7), 0);
						break;
					}
					case "maand": {
						chartOptions.series[0].data =
							res.data.huidige.length > 0
								? res.data.huidige.map((item) => item.waarde)
								: _.fill(Array(7), 0);
						chartOptions.series[1].data =
							res.data.vorige.length > 0
								? res.data.vorige.map((item) => item.waarde)
								: _.fill(Array(7), 0);
						break;
					}
					case "jaar": {
						chartOptions.series[0].data =
							res.data.huidige.length > 0
								? res.data.huidige.map((item) => item.waarde)
								: _.fill(Array(11), 0);
						chartOptions.series[1].data =
							res.data.vorige.length > 0
								? res.data.vorige.map((item) => item.waarde)
								: _.fill(Array(11), 0);

						const currentDataLength = chartOptions.series[0].data.length;
						const previousDataLength = chartOptions.series[1].data.length;
						if (currentDataLength < previousDataLength) {
							const difference = previousDataLength - currentDataLength;
							chartOptions.series[0].data = [
								...chartOptions.series[0].data,
								...new Array(difference).fill(0),
							];
						}
						break;
					}
					default: {
						// if (res.data.huidige.length > 0) {
						//     firstDate = moment(res.data.huidige[0].datum).isoWeekday();
						// } else if (res.data.vorige.length > 0) {
						//     firstDate = moment(res.data.vorige[0].datum).isoWeekday();
						// }
						if (res.data.huidige.length === 0 && res.data.vorige.length === 0) {
							chartOptions.series[0].data = [];
							chartOptions.series[1].data = [];
						} else {
							let weekData = _.fill(Array(7), 0);
							_.each(res.data.huidige, (item) => {
								weekData[moment(item.datum).isoWeekday() - 1] = item.waarde;
							});
							chartOptions.series[0].data = weekData;
							weekData = _.fill(Array(7), 0);
							_.each(res.data.vorige, (item) => {
								weekData[moment(item.datum).isoWeekday() - 1] = item.waarde;
							});
							chartOptions.series[1].data = weekData;
						}
						break;
					}
				}
				// setting chart options
				switch (periode) {
					case "maand": {
						chartOptions.plotOptions = {
							series: {
								pointStart: date.unix() * 1000,
								pointInterval: 7 * 24 * 3600 * 1000,
							},
						};
						chartOptions.xAxis.dateTimeLabelFormats.day = "%b '%y";
						chartOptions.tooltip.xDateFormat = "%Y-%m-%d";
						chartOptions.xAxis.tickInterval = 7 * 24 * 3600 * 1000;
						break;
					}
					case "jaar": {
						chartOptions.plotOptions = {
							series: {
								// Adding some days to the start date ensures the first label starts at January instead of December
								pointStart: date.add(15, "days").unix() * 1000,
								pointInterval: 31 * 24 * 3600 * 1000,
							},
						};
						chartOptions.xAxis.dateTimeLabelFormats.day = "%b '%y";
						chartOptions.tooltip.xDateFormat = "%b '%y";
						chartOptions.xAxis.tickInterval = 30 * 24 * 3600 * 1000;
						break;
					}
					default: {
						chartOptions.xAxis.dateTimeLabelFormats.day = "%A";
						chartOptions.tooltip.xDateFormat = "%A";
						chartOptions.xAxis.tickInterval = 24 * 3600 * 1000;
						chartOptions.plotOptions = {
							series: {
								pointStart: Date.UTC(2017, 10, 6 + firstDate, 7),
								pointInterval: 24 * 3600 * 1000,
							},
						};
						break;
					}
				}
			}
			return res.data;
		},
		queryStartDate(store, query) {
			return queryStartDate(query);
		},
	},
};

export default module;
