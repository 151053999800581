






















































































































































































import {
	type PropType,
	computed,
	defineComponent,
	reactive,
	ref,
	watch,
} from "@vue/composition-api";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { z } from "zod";
import Icon from "@/components/elements/Icon.vue";
import { coreApi } from "@/lib/backend";
import type { CreateAppointmentSlotArgs } from "@/lib/backend/core.api";
import { DAYS, DAYS_LABEL, DaySchema } from "@/lib/backend/helpers";
import Plus from "@/lib/icons/micro/plus.vue";
import Trash from "@/lib/icons/micro/trash.vue";
import { buttonVariants } from "@/lib/pfg/components/button";
import { labelVariants } from "@/lib/pfg/components/label";
import { useGyms } from "@/lib/query/hooks/useGyms";
import { logger } from "@/logger";
import { useRoosterStore } from "@/pinia/rooster";

type Appointment = Pick<CreateAppointmentSlotArgs, "day" | "time">;

const SlotSchema = z.object({
	day: DaySchema,
	time: z.string().regex(/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/, "Invalide tijd"),
});

export default defineComponent({
	name: "OverlayCoachMomentCreate",
	components: {
		Icon,
		Plus,
		Trash,
	},
	props: {
		data: {
			type: Object as PropType<{ gymId: number | "all" | undefined }>,
			default: () => ({}),
		},
	},
	emits: ["close"],
	setup(props, { emit }) {
		const queryClient = useQueryClient();
		const roosterStore = useRoosterStore();
		const { data: gyms } = useGyms();

		const options = reactive<
			Pick<CreateAppointmentSlotArgs, "itemType" | "duration"> & {
				gymId: CreateAppointmentSlotArgs["gymId"] | undefined;
				roomId: CreateAppointmentSlotArgs["roomId"] | undefined;
			}
		>({
			itemType: "live",
			duration: 55,
			gymId: props.data.gymId === "all" ? undefined : props.data.gymId,
			roomId: 0,
		});

		watch(
			options,
			(value) => {
				value.roomId =
					gyms.value
						?.find((gym) => gym.id === value.gymId)
						?.zalen?.find((room) => room.naam === "Fitness zaal")?.id || 0;
			},
			{ immediate: true, deep: true },
		);

		const gym = computed(() => gyms.value?.find((gym) => gym.id === options.gymId));
		const rooms = computed(() => gym.value?.zalen ?? []);

		const slots = ref<Partial<Appointment>[]>([
			{
				day: undefined,
				time: undefined,
			},
		]);

		const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation({
			mutationKey: ["roosters", "create"] as const,
			mutationFn: async (
				variables: Parameters<typeof coreApi.appointmentSlots.appointmentSlotsControllerAdd>[0],
			) =>
				await coreApi.appointmentSlots
					.appointmentSlotsControllerAdd(variables)
					.then((response) => response.data),
		});

		async function submit() {
			try {
				await mutateAsync({
					slots: z
						.array(SlotSchema)
						.parse(
							slots.value.map((slot) => ({
								...slot,
								time: `${slot.time}:00`,
							})),
						)
						.map((slot) => ({
							...options,
							gymId: z.number().parse(options.gymId),
							roomId: z.number().parse(options.roomId),
							...slot,
						})),
				});

				queryClient.refetchQueries({ queryKey: ["roosters", "all"] });
				queryClient.refetchQueries({ queryKey: ["roosters", roosterStore.id, "all"] });
				queryClient.refetchQueries({ queryKey: ["roosters", roosterStore.id, options.gymId] });

				emit("close");
			} catch (error) {
				logger.error("Failed to create coach moment", error);
			}
		}

		return {
			buttonVariants,
			labelVariants,
			DAYS,
			DAYS_LABEL,
			props,
			gyms,
			gym,
			rooms,
			options,
			slots,
			submit,
			isSuccess,
			isLoading,
			isError,
			error,
		};
	},
});
