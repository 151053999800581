<template>
	<div class="pg_content_1-1">
		<header class="pg_header">
			<div class="pg_header_heading">
				<h1 class="s_elem-has-icon-left a_fill_default">Meld je probleem</h1>
			</div>

			<div class="pg_header_description">
				<p v-if="!closeMessage">
					Ben je een probleem tegen gekomen. Stuur dan een mail naar: dev@stijlbreuk.nl
				</p>
				<ul>
					<li>Gebruik als onderwerp: ProfitGym Club App probleem.</li>
					<li>Wat probeerde je te doen?</li>
					<li>Wat gebeurde er wat niet goed ging?</li>
					<li>Noem je naam, en de locatie van filiaal</li>
				</ul>
				<p v-if="closeMessage">{{ closeMessage }}</p>
			</div>
		</header>

		<hr />

		<div class="a_flex a_justify_flex-end">
			<button
				class="button v_ghost_brand_secondary v_has-icon-right v_smaller a_margin-left-10"
				@click="$emit('close')"
			>
				Sluiten
			</button>
			<!-- <button v-if="!closeMessage" @click="deleteLesson" class="button v_ghost_brand_primary v_has-icon-right v_icon-size-10 v_smaller a_margin-left-10">
                Verwijderen
                <icon :iconId="'icon_cross'"></icon>
            </button> -->
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	name: "ModalGroepslesVerwijderen",
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			closeMessage: "",
		};
	},
	methods: {
		...mapActions("rooster", ["removeLes"]),
		deleteLesson() {
			this.removeLes(this.data.id)
				.then(() => {
					this.closeMessage = "De les is succesvol verwijderd!";
				})
				.catch((err) => {
					this.closeMessage = `Er is iets fout gegaan probeer het later nog eens of neem contact op met de beheerder: ${err.message}`;
				});
		},
	},
};
</script>

<style scoped lang="scss"></style>
