<template>
	<span class="svg-container">
		<svg role="img">
			<use :xlink:href="'#' + iconId"></use>
		</svg>
	</span>
</template>

<script>
export default {
	name: "Icon",
	props: {
		iconId: {
			type: String,
			default: "",
		},
	},
};
</script>

<style lang="scss"></style>
