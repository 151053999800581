import { z } from "zod";
import type { CreateAppointmentSlotArgs } from "@/lib/backend/core.api";

export const DAYS: ReadonlyArray<CreateAppointmentSlotArgs["day"]> = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
] as const;

export const DaySchema = z.enum([
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
]);

export type Day = (typeof DAYS)[number];

export const DAYS_LABEL: Record<AutocompleteOptions<Day>, string> = {
	monday: "Maandag",
	tuesday: "Dinsdag",
	wednesday: "Woensdag",
	thursday: "Donderdag",
	friday: "Vrijdag",
	saturday: "Zaterdag",
	sunday: "Zondag",
};
