<template>
	<div>
		<div class="pg_user">
			<div class="pg_user_meta">
				<button class="pg_user_name" @click="openUserMenu('links')">
					{{ userInfo.naam }}
				</button>
				<div class="pg_report-problem">
					<button class="pg_report-problem_btn" @click="reportProblem">meld probleem</button>
				</div>
			</div>
			<button class="pg_user_avatar" @click="openUserMenu('links')">
				<figure class="pg_user_avatar_image" :style="`background-image: url(${image});`"></figure>
			</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
	name: "User",
	data() {
		return {};
	},
	computed: {
		...mapState("auth", ["userInfo"]),
		image() {
			if (this.userImage) return this.userImage;
			return "static/img/trainers/avatar-placeholder.png";
		},
	},
	methods: {
		...mapActions("userMenu", ["openUserMenu"]),
		...mapActions("modal", ["openModal"]),
		reportProblem() {
			this.openModal({ name: "meld-probleem" });
		},
	},
};
</script>

<style lang="scss" scoped>
////
////    USER
////

.pg_user {
	display: flex;
	align-items: center;
}
.pg_user_meta {
	text-align: right;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	padding-right: 1em;

	@include breakpoint(medium-portrait) {
		padding-right: 2em;
	}
}
.pg_user_name {
	@include font-headings-bold;
	font-size: 120%;
	max-width: 240px;
	color: $brand-primary;
	user-select: none;

	@include breakpoint(medium-portrait down) {
		display: none;
	}
}
.pg_report-problem {
	&_btn {
		font-size: 80%;
		line-height: 60%;
		margin-top: 10px;
		user-select: none;
		&:hover {
			text-decoration: underline;
		}
	}
}
.pg_user_links {
	@include breakpoint(medium-portrait down) {
		font-size: 85%;
	}
}
.pg_user_avatar {
	display: block;
	position: relative;
	width: $pg_top-bar_height * 0.66;
	height: $pg_top-bar_height * 0.66;
	border: solid 2px $brand-white;
	position: relative;
	border-radius: 100%;
	background: $brand-white;
	box-shadow: $default-box-shadow;
	@include breakpoint(medium) {
		width: $pg_top-bar_height;
		height: $pg_top-bar_height;
		border: solid 4px $brand-white;
	}
}
.pg_user_avatar_image {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	border-radius: 100%;
	background-size: cover;
}
</style>
