import moment from "moment";
// import config from '@/config';
// import Vue from 'vue';
// import config from '@/config';
import { performForAllApis } from "@/lib/backend";
import clubAuth from "@/lib/club-auth";

const http = clubAuth.http;

const branch = {
	namespaced: true,
	state: {
		items: [],
		selected: {},
	},

	mutations: {
		setItems(state, payload) {
			state.items = payload;
		},
		select(state, payload) {
			state.selected = payload;
			const location = state.selected ? state.selected.mappingNaam : "";
			//http.defaults.headers['Gym-Location'] = 'Zwolle-Dieze';
			http.defaults.headers["Gym-Location"] = location;
			performForAllApis((api) => (api.instance.defaults.headers.common["Gym-Location"] = location));
		},
	},

	actions: {
		async load({ rootState, commit, dispatch }) {
			const response = await dispatch("rooster/getVestigingen", null, {
				root: true,
			});

			if (!rootState.vestiging.vestigingen.length) {
				await dispatch("vestiging/getVestigingen", null, { root: true });
			}

			// map vesitigingen
			const vesitigingen = response
				.map((item) => {
					item.opening = moment(item.opening ? item.opening : Date.now());
					item.vestigingNaam = item.mappingNaam.replace(/-/g, " ");

					if (item.mappingNaam === "Zwolle") {
						item.vestigingNaam = "Zwolle Dieze";
					} else if (item.mappingNaam === "Stadshagen") {
						item.vestigingNaam = "Zwolle Stadshagen";
					} else if (item.mappingNaam === "Nieuw-Vennep") {
						item.vestigingNaam = "Nieuw-Vennep"; // Leave this: one of the exceptions
					}

					const root = rootState.vestiging.vestigingen.find((vestiging) => {
						return vestiging.naam === item.vestigingNaam;
					});

					return {
						...root,
						...item,
					};
				})
				.sort((a, b) => a.mappingNaam.localeCompare(b.mappingNaam));

			commit("setItems", vesitigingen);
			commit("select", vesitigingen.length > 0 ? vesitigingen[vesitigingen.length - 1] : {});
		},
	},
};

export default branch;
