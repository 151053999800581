import clubAuth from "@/lib/club-auth";

const module = {
	namespaced: true,
	state: {},
	mutations: {},
	actions: {
		async getShares() {
			const res = await clubAuth.http.get("/lidmaatschappen/verdeling-abonnementen");
			return res.data;
		},
		async getArrangementenShares() {
			const res = await clubAuth.http.get("/lidmaatschappen/verdeling-arrangementen");
			return res.data;
		},
		async getExtraShares() {
			const res = await clubAuth.http.get("/lidmaatschappen/extras");
			return res.data;
		},
		async getReferalStatistics(state, { periode, vergelijkMet, type }) {
			const res = await clubAuth.http.get(
				`/statistieken/grafiek/${type}/${periode}/${vergelijkMet}`,
			);
			return res.data;
		},
		async getAflopendeAbonnementen(state, filter) {
			const res = await clubAuth.http.get(`/lidmaatschappen/aflopende-abonmenenten/${filter}`);
			return res.data;
		},
	},
};

export default module;
