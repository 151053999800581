import config from "@/config";
import clubAuth from "@/lib/club-auth";
import { logger } from "@/logger";

const http = clubAuth.http;

const modal = {
	namespaced: true,
	state: {
		userName: "",
		userImage: "",
	},
	getters: {},
	mutations: {
		setUserName(state, payload) {
			state.userName = payload;
		},
		setUserImage(state, payload) {
			state.userImage = payload;
		},
	},
	actions: {
		getUser({ commit }) {
			http
				.get(`${config.club.server}/ingeloged`)
				.then((res) => {
					if (res.status === 200) {
						if (res.data && res.data.naam) {
							logger.log("ingelogd naam", res.data.naam);
							commit("setUserName", res.data.naam);
						}
						if (res.data && res.data.foto) {
							commit("setUserImage", res.data.foto);
						}
					}
				})
				.catch(logger.error);
		},
	},
};

export default modal;
