/* eslint-disable @typescript-eslint/no-explicit-any */
import type { PluginObject } from "vue";
import { Toaster } from "./component";
import { useVueSonner } from "./hooks";
import { toast } from "./state";
import type {
	Action,
	ExternalToast,
	ToastClasses,
	ToastT,
	ToastToDismiss,
	ToasterProps,
} from "./types";

export {
	Toaster,
	toast,
	useVueSonner,
	type ToasterProps,
	type ToastT,
	type ExternalToast,
	type Action,
	type ToastClasses,
	type ToastToDismiss,
};

const plugin: PluginObject<any> = {
	install(app) {
		app.component("Toaster", Toaster);
	},
};

export default plugin;
