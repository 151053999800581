import Vue from "vue";
import Router from "vue-router";
// import axios from 'axios';
import clubAuth from "@/lib/club-auth";
import store from "@/store/store";

Vue.use(Router);

const router = new Router({
	linkActiveClass: "s_active",
	routes: [
		// routes
		{
			name: "login",
			path: "/login",
			component: async () =>
				await import(/* webpackChunkName: "pages/login" */ "@/components/pages/Login.vue"),
		},
		{
			path: "/",
			component: async () =>
				await import(/* webpackChunkName: "frame" */ "@/components/pages/Frame.vue"),
			children: [
				// routes
				{
					name: "coaches",
					path: "/coaches",
					component: async () =>
						await import(
							/* webpackChunkName: "pages/coaches" */ "@/components/pages/Coaches/Index.vue"
						),
				},
				{
					name: "groepslessen",
					path: "groepslessen",
					component: async () =>
						await import(
							/* webpackChunkName: "pages/groepslessen" */ "@/components/pages/Groepslessen/Index.vue"
						),
					// children: [
					//     // this is also a route record
					//     { path: 'detail', component: GroepslesDetail },
					// ],
				},
				{
					name: "home",
					path: "",
					component: async () =>
						await import(/* webpackChunkName: "pages/home" */ "@/components/pages/Home/Index.vue"),
				},
				{
					name: "settings",
					path: "/settings",
					component: async () =>
						await import(
							/* webpackChunkName: "pages/settings" */ "@/components/pages/Settings/Index.vue"
						),
					redirect: {
						name: "settings/coaches",
					},
					children: [
						{
							name: "settings/targets",
							path: "targets",
							component: async () =>
								await import(
									/* webpackChunkName: "pages/settings/targets" */ "@/components/pages/Settings/partials/SettingMembershipTargets.vue"
								),
							beforeEnter(to, from, next) {
								if (!store.state.auth.userInfo.admin) {
									if (from.name == null) {
										return next("/");
									}
									return next(false);
								}
								next();
							},
						},
						{
							name: "settings/coaches",
							path: "coaches",
							component: async () =>
								await import(
									/* webpackChunkName: "pages/settings/coaches" */ "@/components/pages/Settings/partials/SettingCoaches.vue"
								),
						},
					],
				},
				{
					name: "kpi-dashboard",
					path: "kpi-dashboard",
					component: async () =>
						await import(
							/* webpackChunkName: "pages/kpi-dashboard" */ "@/components/pages/KpiDashboard/Index.vue"
						),
				},
				{
					name: "leden",
					path: "leden",
					component: async () =>
						await import(
							/* webpackChunkName: "pages/leden" */ "@/components/pages/Leden/Index.vue"
						),
				},
				{
					name: "lidmaatschappen",
					path: "lidmaatschappen",
					component: async () =>
						await import(
							/* webpackChunkName: "pages/lidmaatschappen" */ "@/components/pages/Lidmaatschappen/Index.vue"
						),
				},
				{
					name: "groepsles-planning",
					path: "groepsles-planning",
					component: async () =>
						await import(
							/* webpackChunkName: "pages/groepsles-planning" */ "@/components/pages/GroepslesPlanning/Index.vue"
						),
				},
				{
					name: "groepsles-detail",
					path: "groepsles-planning/detail/:id",
					props: true,
					component: async () =>
						await import(
							/* webpackChunkName: "pages/groepsles-planning/detail" */ "@/components/pages/GroepslesDetail.vue"
						),
				},
				{
					name: "Coach momenten",
					path: "coach-momenten",
					component: async () =>
						await import(
							/* webpackChunkName: "pages/coach-momenten" */ "@/components/pages/CoachMomenten/Index.vue"
						),
				},
			],
		},
	],
});

router.beforeEach((to, from, next) => {
	if (!clubAuth.idAuthorized() && to.name !== "login") {
		return next("/login");
	} else if (clubAuth.idAuthorized() && to.name === "login") {
		return next(from.path);
	}

	return next();
});

router.beforeEach((to, from, next) => {
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
	return next();
});

// axios.interceptors.response.use(response => response, (error) => {
//     debugger;
//     if (error.status === 401) {
//         localStorage.removeItem('club_access_token');
//         localStorage.removeItem('club_refresh_token');
//         localStorage.removeItem('loglevel:webpack-dev-server');
//         localStorage.removeItem('vestigingId');
//         router.go('/login');
//     }
//     return Promise.reject(error);
// });

export default router;
