import clubAuth from "@/lib/club-auth";

const module = {
	namespaced: true,
	state: {},
	mutations: {},
	actions: {
		async getAverageAttendance() {
			const res = await clubAuth.http.get("leden/gemiddelde-opkomst");
			return res.data;
		},
		async getInactiveMembers() {
			const res = await clubAuth.http.get("leden/recentheid");
			return res.data;
		},
		async getMembershipTime() {
			const res = await clubAuth.http.get("leden/aantal-jaar-lid");
			return res.data;
		},
		async getAgeMembers(_, vestiging) {
			let currentHeader;
			if (vestiging) {
				currentHeader = clubAuth.http.defaults.headers["Gym-Location"];
				clubAuth.http.defaults.headers["Gym-Location"] = vestiging.mappingNaam;
			}

			let res = await clubAuth.http.get("/leden/verdeling-leeftijd");

			if (currentHeader) {
				clubAuth.http.defaults.headers["Gym-Location"] = currentHeader;
			}

			return res.data;
		},
		async getGenderMembers(_, vestiging) {
			let currentHeader;
			if (vestiging) {
				currentHeader = clubAuth.http.defaults.headers["Gym-Location"];
				clubAuth.http.defaults.headers["Gym-Location"] = vestiging.mappingNaam;
			}

			let res = await clubAuth.http.get("/leden/verdeling-geslacht");

			if (currentHeader) {
				clubAuth.http.defaults.headers["Gym-Location"] = currentHeader;
			}

			return res.data;
		},
	},
};

export default module;
